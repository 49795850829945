import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import Modal from "../../../components/modal";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {
    createEmail,
    updateEmail,
    clear_email,
    deleteEmail,
    UPDATE_EMAIL,
    DELETE_EMAIL
} from "../../../redux/email_zones";
import {get_all_zones} from "../../../redux/zones";
import LoadingSpinner from "../../../components/loading-spinner";


const UpdateEmail = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {id} = useParams();
    const _zones = useSelector((state) => state.zone_module._get_all_zones);

    const {_create_email, _update_email, _delete_email} = useSelector((state) => state.email_module);

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [form, setForm] = useState({});
    const [formEmail, setFormEmail] = useState({
        "correo": '',
        "id_zona": id
    });

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (_zones && _zones.data) {
            // eslint-disable-next-line
            _zones.data.datos.map((item) => {
                if (item.id === parseInt(id)) {
                    setForm(item);
                }
            });
        }
    }, [_zones, id]);

    useEffect(() => {
        if (_update_email) {
            if (_update_email.status === 200) {
                toast.success("Actualizado correctamente");
                dispatch(clear_email(UPDATE_EMAIL));
                dispatch(get_all_zones(token))
                setModal(false);
                setIdEmail(null);
                setIsLoading(false);
            } else if (_update_email.status !== 0) {
                if (_update_email.message) {
                    toast.error(_update_email.message);
                    dispatch(clear_email(UPDATE_EMAIL));
                    setIsLoading(false);
                }
            }
        }
    }, [_update_email, dispatch, history, token]);

    const [permisos, setPermisos] = useState([]);

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);

    const [modal, setModal] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [idEmail, setIdEmail] = useState(null);

    useEffect(() => {
        if (_create_email.status === 201) {
            toast.success(_create_email.data);
            dispatch(clear_email(DELETE_EMAIL));
            dispatch(get_all_zones(token));
            setIdEmail(null);
            setFormEmail({
                "correo": '',
                "id_zona": id
            });
            setModal(false);
            setIsLoading(false);
        } else if (_create_email.status !== 0) {
            dispatch(clear_email(DELETE_EMAIL));
            toast.error(_create_email.message);
            setIsLoading(false);
        }
    }, [_create_email, history, token, dispatch, id]);

    useEffect(() => {
        if (_delete_email.status === 200) {
            toast.success(_delete_email.data);
            dispatch(clear_email(DELETE_EMAIL));
            dispatch(get_all_zones(token));
            setIdEmail(null);
            setModalDelete(false);
            setIsLoading(false);
        } else if (_delete_email.status !== 0) {
            dispatch(clear_email(DELETE_EMAIL));
            toast.error(_delete_email.message);
            setIsLoading(false);
        }
    }, [_delete_email, history, token, dispatch]);

    const handleDelete = () => {
        setIsLoading(true);
        dispatch(deleteEmail(token, idEmail));
    };


    const handleChangeEmail = (e) => {
        setFormEmail({
            ...formEmail,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        //validate fields
        if (formEmail.correo === "") {
            toast.error("Debe ingresar el correo");
            setIsLoading(false);
            return;
        } else {
            const validEmail =  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
            if(!validEmail.test(formEmail.correo) ){
                toast.error("Correo no válido");
                setIsLoading(false);
                return true;
            }
        }
        if (idEmail === null) {
            dispatch(createEmail(token, formEmail))
        } else {
            dispatch(updateEmail(token, id, formEmail));
        }
    };

    return (
        <div className="app-content content">
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Correos"
                            items={[
                                {label: "Lista", link: "/zonas-correos"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            {/*<form className="card" onSubmit={handleSubmit}>*/}
                            <div className="card">

                                <div className="card-header">
                                    <h4 className="card-title">Correos por zonas</h4>
                                </div>
                                <div className="card-body">
                                    {(form && form.nombre) && (
                                        <div className="row">
                                            <div className="col-6">
                                                <h6>Zona: {form.nombre}</h6>
                                            </div>
                                            <div className="col-6">
                                                <h6>Jefe zonal: {form.usuario_jefe_zonal.nombres}</h6>
                                            </div>
                                        </div>
                                    )}

                                    {
                                        permisos.filter(x => x.codigo === 'add_email').length > 0 &&
                                        (
                                            <div className="mt-2">
                                                <button className="btn btn-success" type="button" onClick={() => {setModal(true)}}>Crear correo</button>
                                            </div>
                                        )

                                    }
                                    {(form && form.correos) && (
                                        <div className="mt-2">
                                            <table className="table table-bordered">
                                                <tr>
                                                    <th className="text-center">Correos</th>
                                                    {
                                                        permisos.filter(x => x.codigo === 'change_email').length > 0 &&
                                                        (
                                                            <th className="text-center">Acciones</th>
                                                        )

                                                    }
                                                </tr>
                                                {form.correos.map((x) => (
                                                    <tr>
                                                        <td>{x.correo}</td>
                                                        {
                                                            permisos.filter(x => x.codigo === 'change_email' || x.codigo === 'delete_email').length > 0 && (
                                                                <td className="text-center">
                                                                    {permisos.filter(x => x.codigo === 'change_email').length > 0 && (
                                                                        // eslint-disable-next-line
                                                                        <a href="#" onClick={() => {setModal(true); setIdEmail(x.id)}} className="mt-2" data-popup="tooltip" title="Editar" data-trigger="manual">
                                                                            <i
                                                                                className={`badge-circle badge-circle-light-primary bx bx-edit font-medium-1`}
                                                                            ></i>
                                                                        </a>
                                                                    )}
                                                                    {permisos.filter(x => x.codigo === 'delete_email').length > 0 && (
                                                                        // eslint-disable-next-line
                                                                        <a href="#" onClick={() => {setModalDelete(true); setIdEmail(x.id)}} className="mt-2" data-popup="tooltip" title="Eliminar" data-trigger="manual">
                                                                            <i
                                                                                className={`badge-circle badge-circle-light-danger bx bxs-trash font-medium-1`}
                                                                            ></i>
                                                                        </a>
                                                                    )}
                                                                </td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </table>
                                        </div>
                                    )}
                                    {/*        <div className="row">*/}
                                    {/*            <div className="col-md-12">*/}
                                    {/*                <Input*/}
                                    {/*                    id="correo"*/}
                                    {/*                    name="correo"*/}
                                    {/*                    label="Correo"*/}
                                    {/*                    value={form.correo}*/}
                                    {/*                    onChange={handleChange}*/}
                                    {/*                />*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="card-footer d-flex justify-content-end">*/}
                                    {/*        <Link to="/correos" className="btn btn-danger m-1">*/}
                                    {/*            Cancelar*/}
                                    {/*        </Link>*/}

                                    {/*        <Button*/}
                                    {/*            type="submit"*/}
                                    {/*            className="btn btn-success"*/}
                                    {/*            onClick={() => {*/}
                                    {/*            }}*/}
                                    {/*            text="Guardar"*/}
                                    {/*            theme="success"*/}
                                    {/*        />*/}
                                </div>
                            </div>
                            {/*</form>*/}
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main" show={modal} style={{width: "40%"}}>
                <div className="modal-content">
                    <form onSubmit={handleSubmit}>
                        <div className="modal-header">
                            {idEmail === null ?
                                <h5>Crear correo</h5>
                                :
                                <h5>Editar correo</h5>
                            }
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <Input
                                    id="correo"
                                    name="correo"
                                    label="Correo"
                                    onChange={handleChangeEmail}
                                    value={formEmail.correo}
                                />
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="button" className="btn btn-outline-dark" onClick={() => {
                                setModal(false);
                                setIdEmail(null);
                                setFormEmail({
                                    correo: "",
                                    id_zona: id,
                                })
                            }}> Cancelar
                            </button>
                            <Button type="submit" text="Guardar" theme="success" classes="m-1"/>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal className="modal-main" show={modalDelete} style={{width: "40%"}}>
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="text-center">
                            <h5>¿Está seguro de eliminar el registro?</h5>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => {handleDelete()}}>
                            SI
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={() => {setModalDelete(false)}}>
                            NO
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default UpdateEmail;
