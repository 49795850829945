import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import Select from "../../../components/select";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {
    create_zones,
    CREATE_ZONES_SUCCESS,
    get_all_zones,
    clear_zone
} from '../../../redux/zones';
import LoadingSpinner from "../../../components/loading-spinner";

/** @module  Pages/Zone/CreateZone */


const CreateZone = () => {
    let history = useHistory();
    const _users = useSelector(
        (state) => state.users_module._get_all_users
    );
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _create_zones = useSelector(
        (state) => state.zone_module._create_zones
    );

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const [form, setForm] = useState({
        nombre: "",
        id_usuario_jefe_zona: 0,
    });
    const [users, setUsers] = useState([]);

    useEffect(() => {
        console.log(_users)
        if (_users.data) {
            setUsers(_users.data);
        }
    }, [_users]);

    useEffect(() => {
        if (_create_zones) {
            if (_create_zones.status === 201) {
                toast.success("La zona ha sido creado correctamente");
                dispatch(clear_zone(CREATE_ZONES_SUCCESS));
                dispatch(get_all_zones(token));
                setIsLoading(false);
                history.push("/zonas");
            } else if (_create_zones.status !== 0) {
                if (_create_zones.message) {
                    toast.error(_create_zones.message)
                    dispatch(clear_zone(CREATE_ZONES_SUCCESS));
                    setIsLoading(false);
                }
            }
        }
    }, [_create_zones, dispatch, history, token]);

    const handleChange = (e) => {
        if (e.target.name === "id_usuario_jefe_zona") {
            //parse to int
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        //validate data
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre");
            setIsLoading(false);
            return;
        }
        if (form.id_usuario_jefe_zona === 0) {
            toast.error("Debe seleccionar un usuario");
            setIsLoading(false);
            return;
        }
        dispatch(create_zones(token, form));
    }

    return (
        <div className="app-content content">
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Zona"
                            items={[
                                {label: "Zonas", link: "/zonas"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Crear Zona</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                label="Nombre"
                                                name="nombre"
                                                onChange={handleChange}
                                                value={form.nombre}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Usuario jefe zonal</label>

                                                <Select
                                                    label="Usuario"
                                                    name="id_usuario_jefe_zona"
                                                    options={users.map((item) => ({
                                                        name: item.nombres + ' ' + item.apellidos,
                                                        id: item.id,
                                                    }))}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/zonas" className="btn btn-danger m-1">
                                        Cancelar
                                    </Link>
                                    <Button
                                        type="submit"
                                        text={
                                            <>
                                                <i className="bx bx-save"/> Guardar
                                            </>
                                        }
                                        theme="success"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateZone;
