import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {
    create_visitant,
    clear_visitant,
    CREATE_VISITANT,
    get_all_visitants
} from "../../../redux/visitant";
import LoadingSpinner from "../../../components/loading-spinner";

/**
 * renderiza un formulario para crear un nuevo invitado
 * @returns html
 */

const CreateVisitant = () => {
    /* Un hook que le permite enviar acciones a la staore Redux. */
    const dispatch = useDispatch();

    /* Un enlace que le permite navegar mediante programación a través de su aplicación. */
    let history = useHistory();

    /* Desestructuración del token del estado. */
    const {token} = useSelector((state) => state.users_module.login.data) || null;


    /* Un hook que le permite acceder al estado de la staore redux. */
    const _create_visitant = useSelector(
        (state) => state.visitant_module._create_visitant
    );

    /* Un hook que le permite crear un estado en un componente funcional. */
    const [form, setForm] = useState({
        rut: "",
        nombres: "",
        apellidos: "",
        correo: "",
        telefono: "",
        imagen: "",
    });

    const [isLoading, setIsLoading] = useState(false);

    /* Creando una variable de estado llamada msj y una función para actualizarla llamada setMsj. */

    /* Un hook que se llama después de cada renderizado. */
    useEffect(() => {
        if (_create_visitant.status === 201) {
            dispatch(clear_visitant(CREATE_VISITANT));
            dispatch(get_all_visitants(token));
            setIsLoading(false);
            history.push("/invitados");
        } else if (_create_visitant.status !== 0) {
            toast.error(_create_visitant.message);
            dispatch(clear_visitant(CREATE_VISITANT));
            setIsLoading(false);
        }
    }, [_create_visitant, dispatch, history, token]);

    /**
     * La función handleChange toma un evento como argumento y luego establece el estado del formulario
     * en el estado del formulario actual, más el valor del destino del evento.
     * @param e - el objeto del evento
     */
    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    /**
     * Toma el objeto de evento y establece el estado del formulario en el archivo que se cargó
     * @param e - El objeto de evento
     */
    const handleFileChange = (e) => {
        setForm({
            ...form,
            imagen: e.target.files[0],
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        //validate fields
        if (form.rut === "") {
            toast.error("Debe ingresar el rut");
            setIsLoading(false);
            return
        }
        if (form.nombres === "") {
            toast.error("Debe ingresar el nombre");
            setIsLoading(false);
            return
        }
        if (form.apellidos === "") {
            toast.error("Debe ingresar el apellido");
            setIsLoading(false);
            return
        }

        dispatch(create_visitant(token, form));
    };

    return (
        <div className="app-content content">
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Invitados"
                            items={[
                                {label: "Invitados", link: "/invitados"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Crear Invitado</h4>
                                </div>
                                <div className="card-body">
                                    <div
                                        className=" row">
                                        <div className="col-md-4">
                                            <Input
                                                label="Rut"
                                                name="rut"
                                                onChange={handleChange}
                                                value={form.rut}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Nombres"
                                                name="nombres"
                                                onChange={handleChange}
                                                value={form.nombres}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Apellidos"
                                                name="apellidos"
                                                onChange={handleChange}
                                                value={form.apellidos}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Input
                                                label="Correo"
                                                name="correo"
                                                onChange={handleChange}
                                                value={form.correo}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Teléfono"
                                                name="telefono"
                                                onChange={handleChange}
                                                value={form.telefono}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Imagen"
                                                name="imagen"
                                                type="file"
                                                accept="image/*"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/invitados" className="btn btn-danger m-1">
                                        Cancelar
                                    </Link>
                                    <Button
                                        text="Crear"
                                        theme="success"
                                        type="submit"
                                        classes="m-1"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateVisitant;
