import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    update_visitant,
    clear_visitant,
    UPDATE_VISITANT,
    get_all_visitants
} from "../../../redux/visitant";
import {URLAPI} from "../../../config/index";
import LoadingSpinner from "../../../components/loading-spinner";

const UpdateVisitant = () => {
    let history = useHistory();
    const {id} = useParams();
    const dispatch = useDispatch();
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _update_visitant = useSelector(
        (state) => state.visitant_module._update_visitant
    );
    const _get_all_visitants = useSelector(
        (state) => state.visitant_module._get_all_visitants
    );
    const [imagePreview, setImagePreview] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (_update_visitant.status === 200) {
            toast.success(_update_visitant.message);
            dispatch(clear_visitant(UPDATE_VISITANT));
            dispatch(get_all_visitants(token));
            setIsLoading(false);
            history.push("/invitados");
        } else if (_update_visitant.status !== 0) {
            toast.error(_update_visitant.message);
            dispatch(clear_visitant(UPDATE_VISITANT));
            setIsLoading(false);
        }
    }, [_update_visitant, history, token, dispatch]);

    useEffect(() => {
        if (_get_all_visitants.data) {
            // eslint-disable-next-line
            _get_all_visitants.data.map((item) => {
                if (item.id === parseInt(id)) {
                    setForm(item);
                }
            });
        }
    }, [_get_all_visitants, id]);

    const [form, setForm] = useState({
        rut: "",
        nombres: "",
        apellidos: "",
        correo: "",
        telefono: "",
        imagen: "",
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        //validate fields
        if (form.rut === "") {
            toast.error("Debe ingresar el rut");
            setIsLoading(false);
            return;
        }
        if (form.nombres === "") {
            toast.error("Debe ingresar el nombre");
            setIsLoading(false);
            return;
        }
        if (form.apellidos === "") {
            toast.error("Debe ingresar el apellido");
            setIsLoading(false);
            return;
        }
        if (form.correo === "") {
            toast.error("Debe ingresar el correo");
            setIsLoading(false);
            return;
        }
        if (form.telefono === "") {
            toast.error("Debe ingresar el teléfono");
            setIsLoading(false);
            return;
        }

        dispatch(update_visitant(token, id, form));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        let url = URL.createObjectURL(file);
        setImagePreview(url);
        setForm({
            ...form,
            imagen: e.target.files[0],
        });
    };

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div className="app-content content">
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Invitados"
                            items={[
                                {label: "Invitados", link: "/invitados"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Actualizar Invitado</h4>
                                </div>
                                <div className="card-body">
                                    <div className=" row">
                                        <div className="col-md-4">
                                            <Input
                                                label="Rut"
                                                name="rut"
                                                onChange={handleChange}
                                                value={form.rut}
                                                readonly="readonly"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Nombres"
                                                name="nombres"
                                                onChange={handleChange}
                                                value={form.nombres}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Apellidos"
                                                name="apellidos"
                                                onChange={handleChange}
                                                value={form.apellidos}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="
                      row"
                                    >
                                        <div className="col-md-4">
                                            <Input
                                                label="Correo"
                                                name="correo"
                                                onChange={handleChange}
                                                value={form.correo}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Teléfono"
                                                name="telefono"
                                                onChange={handleChange}
                                                value={form.telefono}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Imagen"
                                                name="imagen"
                                                type="file"
                                                accept="image/*"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <img
                                                src={
                                                    imagePreview == null
                                                        ? `${URLAPI}${form.foto}`
                                                        : imagePreview
                                                }
                                                alt="imagen"
                                                width={60}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/invitados" className="btn btn-danger m-1">
                                        Cancelar
                                    </Link>
                                    <Button
                                        text="Guardar"
                                        theme="success"
                                        type="submit"
                                        classes="m-1"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateVisitant;
