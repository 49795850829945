import React, {useEffect, useState, useContext} from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import {useHistory} from "react-router-dom";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import route from "./routes";
import {useSelector, useDispatch} from "react-redux";
import {get_all_companies} from "../redux/company";
import {get_all_cost_centers} from '../redux/cost_center'
import {check_export_employee_status, clear_employee, GET_REPORT_EMPLOYEE, getAllEmployees} from '../redux/employees'
import {getAllSuppliers} from '../redux/supplier'
import {SocketContext} from "../config/ws-client";
import {get_all_visitants} from '../redux/visitant'
import {get_all_users, get_all_groups, get_all_permisions} from '../redux/users'
import {get_all_general_settings} from '../redux/base'
import {getAllJobs} from '../redux/jobs'
import {getAllVisits, clearVisit, GET_REPORT} from '../redux/visits'
// import {getAllEmailVisits} from '../redux/email_visits'
import {get_all_zones} from '../redux/zones'
import {toast} from 'react-toastify';
import {save_notification, check_export_status} from '../redux/visits';
import {TYPE_UPDATE_MESSAGE, TYPE_REPORT_GENERATE, TYPE_CREATE_MESSAGE, TYPE_REPORT_EMPLOYEE_GENERATE} from '../config/ws-client'

const Index = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const _data_login = useSelector((state) => state.users_module.login);
    const _session_data = useSelector((state) => state.users_module._session_data);
    const [permisos, setPermissions] = useState([]);
    const {socket} = useContext(SocketContext);
    const _get_notification = useSelector((state) => state.visits_module._get_notification);
    const [token, setToken] = useState("")

    const audio = new Audio("/base/assets/audio/audio.mp3");

    useEffect(() => {
        if (_data_login.status >= 200) {

            dispatch(get_all_companies(_data_login.data.token));
            dispatch(get_all_cost_centers(_data_login.data.token));
            dispatch(getAllEmployees(_data_login.data.token));
            dispatch(getAllSuppliers(_data_login.data.token));
            dispatch(get_all_visitants(_data_login.data.token));
            dispatch(get_all_users(_data_login.data.token));
            dispatch(getAllJobs(_data_login.data.token));
            dispatch(get_all_groups(_data_login.data.token));
            dispatch(getAllVisits(_data_login.data.token, 1));
            dispatch(get_all_permisions(_data_login.data.token));
            dispatch(get_all_general_settings(_data_login.data.token))
            // dispatch(getAllEmailVisits(_data_login.data.token))
            dispatch(get_all_zones(_data_login.data.token))
            setToken(_data_login.data.token)
        } else {
            history.push("/");
        }

        if (JSON.stringify(_session_data.data) !== "{}") {
            if (_session_data.data.grupo_permiso) {
                if (_session_data.data.grupo_permiso.permisos) {
                    setPermissions(_session_data.data.grupo_permiso.permisos);
                }
            }
        }

    }, [dispatch, _data_login, _session_data, history]);


    useEffect(() => {
        handleMessageWs()
        // eslint-disable-next-line
    }, [socket])

    // const sleep = (ms) => {
    //     return new Promise(resolve => setTimeout(resolve, ms));
    // }

    const handleMessageWs = () => {
        if (socket) {
            socket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.type === TYPE_UPDATE_MESSAGE) {
                    visit_update_message(data, socket)
                    return;
                }

                if (data.type === TYPE_REPORT_GENERATE) {
                    visit_report_generate(data, socket);
                }
                if (data.type === TYPE_REPORT_EMPLOYEE_GENERATE) {
                    employee_report_generate(data, socket);
                }

                if (data.type === TYPE_CREATE_MESSAGE) {
                    visit_create_message(data, socket)
                }

            }
        }
    }


    const visit_update_message = (data, socket) => {
        toast.success(data.message);

        const notification = {
            title: data.title,
            message: data.message,
            result: null,
            id: data.id,
            read: false,
            type: TYPE_UPDATE_MESSAGE,
            show: true,
            date: data.date
        }
        let n = _get_notification.data ?? [];
        n.push(notification)
        dispatch(save_notification(n));
        dispatch(getAllVisits(token, 1))
        audio.play();
    }

    const visit_create_message = (data, socket) => {
        dispatch(getAllVisits(token, 1))
    }


    let timer = null;

    const visit_report_generate = (data, socket) => {
        timer = setInterval(() => {
            check_export_status(token, data.id_task).then(e => {
                let datos = e.data;
                if (e.status === 200) {
                    dispatch(clearVisit(GET_REPORT))
                    const notification = {
                        title: "Reporte Generado ",
                        message: datos.result.nombre,
                        result: datos.result,
                        id: datos.id_task,
                        read: false,
                        date: datos.date,
                        type: TYPE_REPORT_GENERATE,
                        show: true

                    }
                    let n = _get_notification.data ?? [];
                    n.push(notification)
                    dispatch(save_notification(n));

                    audio.play();
                    clearInterval(timer)
                    timer = null;

                }
            }).catch(e => {
                clearInterval(timer)
                timer = null;
            })
        }, 5000)
    }

    let timer_employee = null;
    const employee_report_generate = (data, socket) => {
        timer_employee = setInterval(() => {
            check_export_employee_status(token, data.id_task).then(e => {
                let datos = e.data;
                if (e.status === 200) {
                    dispatch(clear_employee(GET_REPORT_EMPLOYEE))
                    const notification = {
                        title: "Reporte Generado ",
                        message: datos.result.nombre,
                        result: datos.result,
                        id: datos.id_task,
                        read: false,
                        date: datos.date,
                        type: TYPE_REPORT_EMPLOYEE_GENERATE,
                        show: true

                    }
                    let n = _get_notification.data ?? [];
                    n.push(notification)
                    dispatch(save_notification(n));

                    audio.play();
                    clearInterval(timer_employee)
                    timer_employee = null;

                }
            }).catch(e => {
                clearInterval(timer_employee)
                timer_employee = null;
            })
        }, 5000)
    }

    return (
        <Router>
            <div className="header-navbar-shadow"/>
            <Navbar/>
            <Sidebar/>
            <Switch>
                {/*eslint-disable-next-line*/}
                {route.map((item, index) => {
                    if (!item.meta.requiresAuth) {
                        return (
                            <Route
                                path={item.path}
                                exact={item.exact}
                            >
                                {<item.component {...permisos} />}

                            </Route>
                        );
                    }

                    if (permisos.filter(x => item.meta.permission_required.includes(x.codigo)).length > 0) {
                        return (
                            <Route
                                path={item.path}
                                exact={item.exact}
                            >
                                {<item.component {...permisos} />}

                            </Route>
                        );
                    }
                })}
            </Switch>
        </Router>
    );
};
export default Index;
